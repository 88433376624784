import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import List from "components/list";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/core-i9"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/corei9.png"}) { ...eyecatchImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    allCoreIJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Core i9 比較表 | 世代や用途ごとにCore i9の性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Oct 12 2024 00:56:48 GMT+0900 (日本標準時)">更新日:2024年10月12日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Core i9 比較" mdxType="Image" />
    <p>{`Core i9(新世代のCore Ultra 9も含む)はCoreシリーズの中で最も性能が高く、第9世代から投入されたIntelのフラッグシップCPUである。`}</p>
    <p>{`価格は安いものでも５万円を超え、部品一つでノートパソコンが買えてしまうぐらいの値段となるため、ゲームで高いFPSを目指したい、4K動画編集作業を最高の環境で行いたいなど目的を持った購入が不可欠である。`}</p>
    <p>{`ただ、Core i9と言えども２年前に発売されたものだと、最新のCore i5にベンチマークスコアで負けるなど万全ではない。`}</p>
    <p>{`果たして本当にCore i9で良いのか？とパソコンを検討中に悩んだなら本記事及び最後の性能比較表をチェックし、妥当であるかを良く確認して最終的なCPUを判断していこう。`}</p>
    <p>{`尚、Coreシリーズについての概要を知りたい人は`}<Link to="/cpu/core-i/" mdxType="Link">{`Coreシリーズ比較表`}</Link>{`へ、またAMDのCPU RyzenやCeleronなど全てのCPUとの比較は本家`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`で確認して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Core-i9の用途別性能差",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i9%E3%81%AE%E7%94%A8%E9%80%94%E5%88%A5%E6%80%A7%E8%83%BD%E5%B7%AE",
        "aria-label": "Core i9の用途別性能差 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i9の用途別性能差`}</h2>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`基本的にCPUは消費電力が大きいほど性能が高くなる。Core i9ではフラッグシップモデルという性質上中途半端なスペックは許されるはずは無く、KやHを中心にラインナップがされている。Y、Uなどノートパソコンの低消費電力版にあたるCPUは存在しない。`}</p>
    <p>{`ノートパソコンとデスクトップでは性能差はあるが、いずれも発売時には用途別で最速であることが保証されている点は安心材料である。`}</p>
    <h2 {...{
      "id": "Core-i9-vs-Ryzen-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i9-vs-Ryzen-9",
        "aria-label": "Core i9 vs Ryzen 9 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i9 vs Ryzen 9`}</h2>
    <p>{`AMDのフラッグシップモデルであるRyzen 9と比べるとゲーミングの性能では互角であり、動画編集やプログラミングのクリエイティブな分野になるとマルチスレッド性能が高いRyzen 9に軍配が上がる。`}</p>
    <p>{`とはいえAdobeの製品とAMDはソフトウェアのチューニングが進んでおらず安定性が悪いなどの問題を抱えるため、マルチスレッド性能を活かして特定の処理を高速化したいという強い要求が無ければCore i9は正当な良い製品であると言える。`}</p>
    <p>{`IntelとAMDの違いは`}<Link to="/cpu/ryzen-intel/" mdxType="Link">{`RyzenとIntelの違いを比較、どちちを選ぶべきか目的別に提示してみた`}</Link>{`という記事にもまとめているので参考にして欲しい。`}</p>
    <h2 {...{
      "id": "Intel-Core-i9-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core-i9-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Intel Core i9 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Core i9 性能比較表`}</h2>
    <p>{`それではCore i9 CPUの様々な世代、用途における性能比較表を示す。`}</p>
    <p>{`Core i7や他のCPUと比較したい場合はチェックボックスを利用して欲しい。Core i7やCore i5も世代が古いCore i9にベンチマークでは勝利するため注意して検討したい。`}</p>
    <p>{`ベンチマークとして総合的な能力を計測するPassMarkの総合スコアPassMark(M)とシングルスレッドの性能を測るPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば良い`}</em></strong>{`だろう。ただ、PASSMARKの値はベンチマークバージョンにより上下するため、他サイトのスコアは参考にならないので注意しておこう。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`その他、表の項目説明等はまとめておくので必要に応じて参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <Accordion title="Intel CPU末尾文字の意味一覧" mdxType="Accordion">
      <IntelMatsubi mdxType="IntelMatsubi" />
    </Accordion>
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'notedesk']} cpuData={props.data.allCoreIJson.nodes} checkStatus={{
      corenine: true
    }} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      